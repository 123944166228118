<!-- 订单中心 -->
<template>
  <div id="productorder">
    <van-nav-bar :title="$t('m_header.ddzx')" left-arrow @click-left="onClickLeft">
      <template #right>
        <i class="iconfont icon-zijinmingxi" @click="jumpcapital()"></i>
      </template>
    </van-nav-bar>
    <div class="yuebao-cont">
      <div class="amount-block">
        <div class="amount">
          <span class="hasIncome">0.00</span>
        </div>
        <div class="amount-income">
          <div class="totalIncome income">
            <p class="income-price">0.00</p>
            <p class="income-text">{{$t('m_ordercenter.hqje')}}</p>
          </div>
          <div class="rateIncome income">
            <p class="income-price">0.00</p>
            <p class="income-text">{{$t('m_ordercenter.scje')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="order-income">
      <div class="totalIncome income2">
        <p class="income2-price">0.00</p>
        <p class="income2-text">{{$t('m_productList.rsy')}}</p>
      </div>
      <div class="rateIncome income2">
        <p class="income2-price">0</p>
        <p class="income2-text">{{$t('m_productList.ljsy')}}</p>
      </div>
      <div class="rateIncome income2">
        <p class="income2-price">0</p>
        <p class="income2-text">{{$t('m_productList.tgzdds')}}</p>
      </div>
    </div>
    <div class="croller-8haq8">

    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    jumpcapital(){
      this.$router.push("/capital");
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar__right {
  .iconfont {
    font-size: 0.4rem;
    // color: #38373b !important;
  }
}
body,html{
  width: 100%;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  background: #222;
  -webkit-text-size-adjust: none;
  user-select: none;
}
#productorder {
  width: 100%;
  height: 100%;
  position: relative;
  background: top url(../../../assets/img/productorder.png) no-repeat;
  background-size: auto;
  background-size: contain;
  .yuebao-cont {
    padding: 0rem 0.6rem 0rem 0.6rem;
    height: auto;
    .amount-block {
      width: 100%;
      border-radius: 10px;
      text-align: center;
      color: #fdf9f9;
      .amount {
        color: #fdf9f9;
        text-align: center;
        padding: 6px 0;
        .hasIncome {
          font-size: 28px;
        }
      }
      .amount-income {
        display: flex;
        justify-content: space-around;
        .income {
          .income-price {
            font-size: 18px;
          }
          .income-text {
            color: #999999;
            font-size: 12px;
            padding: 10px 0;
          }
        }
      }
    }
  }
  .order-income{
    margin: 0rem 0.2rem 0rem 0.2rem;
    display: flex;
    justify-content: space-around;
    background-color: #373430;
    border-radius: 0.2rem;
    text-align: center;
    .income2{
      .income2-price{
        color: #CC964F;
        font-size: 18px;
        padding: 7px 0;
      }
      .income2-text{
        color: #999999;
        font-size: 12px;
        padding: 7px 0;
      }
    }
  }
  .croller-8haq8{
    height: 642px;
    touch-action: auto;
    user-select: none;
    position: relative;
    overflow: hidden;
    background-color: #222;
  }
}
</style>